<template>
  <div>
    <CCard class="p-0 m-0 form-card">
      <CCardHeader>
        <label class="header-title">ファームウェア管理</label>
        <div class="card-header-actions align-middle">
          <CButton color="primary" size="sm"  v-show="isAdministrator()" @click="isCollapsed=!isCollapsed">{{isCollapsed ? '登録':'一覧'}}モードへ</CButton>
        </div>
      </CCardHeader>
      <CCardBody class="p-0">
        <div>
          <CCollapse :show="!isCollapsed" :duration="200" @finish="handleResize">
            <CCard class="notice_form p-0 m-0">
              <CCardBody>
                <CForm>
                  <CRow>
                    <CCol class="col-6">
                      <CInput class="mt-0" label="登録するファームウェアのバージョン" v-model="formData.firmwareVersion"
                              placeholder="バージョンを入力してください"/>
                    </CCol>
                    <CCol class="col-4">
                      <CInputFile label="ファームウェアファイル" autocomplete="file" inline
                                  @change="formData.file = $event[0]"/>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol class="col-6">
                      <CInput class="mt-0" label="メモ" v-model="formData.memo" placeholder="バージョンのメモを入力してください"/>
                    </CCol>
                    <CCol class="col-6 mx-0">
                      <CRow>
                        <CCol class="mx-0">
                          <label>適用予定日時</label>
                        </CCol>
                      </CRow>
                      <CRow>
                        <Datetime
                            v-model="formData.updateReservTime"
                            :minute-interval="10"
                            position='bottom'
                            button-now-translation="今日" overlay
                            label="選択してください"
                            locale="ja"
                            :no-label="true"
                            :no-header="true"
                            :no-button-now="true"
                            :formatted="'YYYY/MM/DD HH:mm'"
                            :format="'YYYY/MM/DD HH:mm'"
                        />
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol class="col-6">
                      <CInputCheckbox class="mt-0" label="最新バージョン(指定以外全部)" :checked="formData.isLatest"
                                      @update:checked="formData.isLatest = $event"/>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol class="mt-3 text-right">
                      <CButton color="danger" size="sm" class="mb-2" @click="upload">登録</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCollapse>
        </div>

        <CModal :show.sync="showGateway"
                size="xl"
                :centered="true"
                title="ファームウェア指定">
          <!--              <CCard class="mt-1">-->
          <!--                <CCardBody class="usersBody">-->
          <GatewayForFirmware :is-show="showGateway" @specFirmwareSave="specFirmwareSave" :isShow="showGateway" :firmware-id="specFirmwareVersionId"/>
          <!--                </CCardBody>-->
          <!--              </CCard>-->
          <template #footer-wrapper><span></span></template>
        </CModal>


        <CModal :show.sync="specShowGateway"
                size="xl"
                :centered="true"
                title="ファームウェア指定確認">
          <!--              <CCard class="mt-1">-->
          <!--                <CCardBody class="usersBody">-->
          <FirmwareSpecRemove :is-show="specShowGateway"/>
          <!--                </CCardBody>-->
          <!--              </CCard>-->
          <template #footer-wrapper><span></span></template>
        </CModal>
<!--        <vue-good-table -->
<!--            theme="custom-theme"-->
<!--            :columns="columns2" :rows="rows" mode="remote" :fixed-header="fiexedHeader"-->
<!--            @on-page-change="onGoodPageChange" @on-sort-change="onGoodSortChange"-->
<!--            @on-column-filter="onGoodColumnFilter" @on-per-page-change="onGoodPerPageChange"-->
<!--            @on-search="onGoodSearch" :totalRows="totalRecords" :isLoading="isLoading"-->
<!--            :search-options="searchOptions"-->
<!--            :pagination-options="pagingOption" :select-options="selectOptions"-->
<!--            :sort-options="{enabled: true,initialSortBy: {field: 'id', type: 'desc'}}"-->
<!--            max-height='20vh'>-->
<!--          <div slot="table-actions">-->
<!--            <CButton color="warning" size="sm" @click="goodPaging" v-shortkey="['shift', 's']" @shortkey="goodPaging">検索</CButton>-->
<!--          </div>-->
<!--          <div slot="emptystate">対象件数はありません。</div>-->
<!--          <template slot="table-row" slot-scope="props">-->
<!--            <div v-if="props.column.field == 'no'" class="text-center">-->
<!--              {{ (pagingParams.currPage - 1) * pagingParams.perPage + props.index + 1 }}-->
<!--            </div>-->
<!--            <div v-else-if="props.column.field == 'isLatest'" class="text-center">-->
<!--              <CBadge color="success" class="ml-2" v-if="props.row.isLatest">○</CBadge>-->
<!--            </div>-->
<!--            <div v-else-if="props.column.field == 'after'" class="text-center">-->
<!--              <CButton color="success py-0" size="sm" class="mr-1" @click="specModal(props.row)">指定</CButton>-->
<!--            </div>-->

<!--            <span v-else>{{ props.formattedRow[props.column.field] }}</span>-->
<!--          </template>-->
<!--        </vue-good-table>-->
      </CCardBody>
    </CCard>
    <CCard class="p-0 m-0 grid-card">
      <CCardBody class="p-0">
        <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                        :curr-page.sync="pagingParams.currPage"
                        :per-page.sync="pagingParams.perPage"
                        :per-page-list="perPageList"
                        :pages="pages"
                        :search-bar-place-holder="searchBarPlaceHolder"
                        :keyword.sync="pagingParams.keyword">
          <template #tools>
              <CButton color="info" size="sm" class="m-1" @click="specCheckModal" >指定確認</CButton>
          </template>
        </MyBeeSearchBar>
        <BeeGridTable
            ref="bee"
            border
            stripe
            :showFilter="false"
            :columns="columns"
            :loading="isLoading"
            :data="rows"
            :showPager="false"
            :maxHeight="gridCardHeight"
            highlightRow
            no-data-text="データがありません。"
            no-filtered-data-text=""
            :context="context"
            :span-method="customSpan"
            :showVerticalScrollBar="false"
            :showHorizontalScrollBar="false">
          <!-- HEADER ソート可能にするために 必要-->
          <template slot-scope="{ column,index }" slot="idHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="firmwareVersionHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="memoHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="isLatestHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="fileNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="updateReservTimeHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="createdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="cntHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>

          <!-- cell -->
          <template slot-scope="{ row,index }" slot="isLatest">
            <div class="text-center">
              <CBadge color="success" class="ml-2" v-if="row.isLatest">○</CBadge>
            </div>
          </template>

          <template slot-scope="{ row,index }" slot="cnt">
            <div class="text-center">
              <span>{{ row.cnt }}</span>
            </div>
          </template>

          <template slot-scope="{ row,index }" slot="after">
            <div class="text-center">
              <CButton color="success py-0" size="sm" class="mr-1" @click="specModal(row)">指定</CButton>
            </div>
          </template>
        </BeeGridTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Datetime from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import GatewayForFirmware from "@/component/GatewayForFirmware";
import FirmwareSpecRemove from "@/component/parts/FirmwareSpecRemove";

import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";

export default {
  name: 'GatewayFirmwareManage',
  components:{
    Datetime,GatewayForFirmware,MyBeeHeader, MyBeeSearchBar,FirmwareSpecRemove
  },
  mixins: [BeeMix],
  data: () => {
    return {
          requestDate: '',
          columns: [
            // {label: 'No', field: 'no',sortable: false, width: "70px"},
            {key: 'id', title: 'ファームウェアID',headSlot: 'idHead'},
            {key: 'firmwareVersion',headSlot:'firmwareVersionHead', title: 'バージョン',},
            {key: 'memo',headSlot:'memoHead', title: 'メモ'},
            {key: 'isLatest',slot:'isLatest',headSlot:'isLatestHead', title: '最新バージョン',},
            {key: 'fileName',headSlot:'fileNameHead', title: 'ファイル名',},
            {key: 'updateReservTime',headSlot:'updateReservTimeHead', title: '適用予定時間'},
            {key: 'created',headSlot:'createdHead', title: '登録日時', },
            {key: 'cnt',slot:"cnt",headSlot:'cntHead', title: 'IoTゲートウェイ数'},
            {key: 'after',slot:'after',headSlot:'afterHead', title: ' '},
          ],
          pagingParams: Object.assign(BeeMix.data().pagingParams,{sort: [{field: "id", sort: "desc"}]}),
          latestFlg:false,
          searchParams:{},
          searchTargets: [],
          modelNumbers:["5A+","5B+","TCL-003","TM1121","UA-651BLE"],
          formData:{file:null,firmwareVersion:null,memo:null,updateReservTime:null,isLatest:false},
          isFormValid: true,
          specFirmwareVersionId:0,
          showGateway:false,
          specShowGateway:false,
          searchBarPlaceHolder:"バージョン名で検索"
    }
  },
  methods: {
    specModal:function(row){
      this.specFirmwareVersionId  = row.id;
      this.showGateway = true
    },
    specCheckModal:function(){
      this.specShowGateway = true
    },
    toggleLatestFlg:function(){
      this.latestFlg = !this.latestFlg;
      this.goodPaging();
    },
    specFirmwareSave:function(deviceIds){
      this.isLoading = true;
      this.axios.post("/api/gateway/specFirmware",
          {firmwareVersionId: this.specFirmwareVersionId, gatewayIds:deviceIds}
          , {
            headers: {"Content-Type": "application/json"}
          }
      ).then(response => {
        console.log('response', response);
        this.goodPaging();
      }).catch(error => {
        console.log('error', error);
        console.log('error', error.response.data);
      }).finally(() => {
        console.log('finally');
        this.isLoading = false;
        this.showGateway = false;
      });
    },
    columnFilterFn:function(data){
      console.log("columnFilterFn",data);
    },
    createModal() {
      this.modalColor = 'primary';//success(update),primary(create),danger,info,dark
      this.darkModal = true;
      this.modalValue = {"deviceKind": "BLOOD_PRESSURE"};
    }, updateModal(data) {
      this.modalColor = 'success';//success(update),primary(create),danger,info,dark
      this.darkModal = true;
      this.modalValue = Object.assign({}, data.item);
    }, showPatientDetail(data) {
      console.log('showPatientDetail',JSON.stringify(data));

      this.axios.get(`/api/patientInfoDialog/${data.patientId}`).then(response => {
        console.log("/api/devicePatientDialog",JSON.stringify(response.data.result))
        this.patientDialogData = response.data.result;

        this.modalColor = 'primary';//success(update),primary(create),danger,info,dark
        this.patientModal = true;
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    }, upload() {
      this.$swal({title:"登録確認",text:"登録しますか？",buttons:true,dangerMode:true}).then((result) => {
        if (result) {
          delete this.formData["__ob__"];
          var formData = new FormData();
          formData.append("firmwareVersion", this.formData.firmwareVersion);
          formData.append("file", this.formData.file);
          formData.append("memo", this.formData.memo);
          formData.append("updateReservTime", this.formData.updateReservTime);
          formData.append("isLatest", this.formData.isLatest);

          if(this.formData.firmwareVersion == null){
            this.$swal("ファームウェアバージョンを入力してください。");
            return;
          }
          if(this.formData.file == null){
            this.$swal("ファイルを指定してください。");
            return;
          }
          if(this.formData.memo == null){
            this.$swal("メモを入力してください。");
            return;
          }
          if(this.formData.updateReservTime == null){
            this.$swal("適用予定時間を入力してください。");
            return;
          }

          this.axios.post("/api/saveGatewayFirmware",
              formData
              , {
                headers: {"Content-Type": "multipart/form-data"}
              }
          ).then(response => {
            console.log('response', response);
            this.goodPaging();
          }).catch(error => {
            console.log('error', error);
            console.log('error', error.response.data);
          }).finally(() => {
            console.log('finally');
          });
        }
      });
    },
    goodPaging(isPageChange) {
      if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
      this.isLoading = true;
      this.axios.post('/api/gatewayFirmwarePaging',
          {pagingForm:this.pagingParams,latestFlg:/*this.latestFlg*/null}
          , {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.totalRecords = response.data.result.totalElements;
        this.rows = response.data.result.content;
        //ページ件数
        this.pages = response.data.result.totalPages;
        this.topLeft();
      }).catch(error => console.log(error)
      ).finally(() => {
        this.isLoading = false;
      });
    },
    changeCheckBox(data, checkValue) {
      data.item.checked = checkValue;
      this.$set(this.items, data.index, data.item);
    }, allCheck(checkValue) {
      alert(checkValue);
    }
  },

  mounted() {
  },watch:{
    isCollapsed(newValue){
      if(newValue){
        this.formData = {file:null,firmwareVersion:null,memo:null,updateReservTime:null,isLatest:false};
      }
    }
  }

}
</script>
